.form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.form-centered {
    width: 70%;
}

.date-picker {
    width: 100%;
}

.inline-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
}