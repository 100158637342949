.header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    padding: 16px 16px 0 16px;
    background-color: var(--wildsand);
    transition: .3s ease;
    border-bottom: 0px solid var(--saltbox);
    z-index: 20;
}

.header-container-scrolling {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: .3s ease;
}

.header-divider {
    width: 100%;
    height: 1px;
    background: var(--saltbox);
}