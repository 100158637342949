.homeview{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.introimage{
    max-width: 500px;
    max-height: 500px;
}