.setting-card-container{
    width: 80%;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    max-width: 40rem;
}

.setting-card-id{
    display: flex;
    align-items: center;
}

.setting-card-icon{
    font-size: 40px;
    color: var(--corduroy);
}

.setting-card-label{
    font-size: 20px;
    font-weight: 600;
    margin: 20px 10px 20px 20px;
}