.time-entry-card-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.time-entry-card-header {
    background: var(--nandor);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 2px;
    font-weight: 500;
}

.time-entry-card-content {
    background: white;
    color: var(--saltbox);
    display: grid;
    justify-content: center;
    grid-template-columns: 10% 10% 10% 55% 10%;
    padding: 8px 0;
}

.time-entry-card-content-item-centered {
    text-align: center;
}

.time-entry-card-content-action-panel {
    display: flex;
    justify-content: center;
    gap: 2rem;
    color: var(--nandor);
}

.time-entry-card-description {
    height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: .3s ease;
    pointer-events: none;
}

.time-entry-card-description::first-line {
    color: var(--saltbox);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: .3s ease;
}

.time-entry-card-description-expanded {
    text-overflow: clip;
    white-space: pre-wrap;
    overflow: no-content;
    transition: .3s ease;
    height: auto;
}

.time-entry-card-edit-btn {
    background: transparent;
    border: none;
    color: var(--nandor);
}

.time-entry-card-edit-btn:hover {
    background: transparent;
    border: none;
    color: var(--neptune);
    transition: .3s ease;
    transform: scale(1.2);
}

.time-entry-card-delete-btn {
    background: transparent;
    border: none;
    color: var(--nandor);
}

.time-entry-card-delete-btn:hover {
    background: transparent;
    border: none;
    color: var(--watermelon);
    transition: .3s ease;
    transform: scale(1.2);
}


@media only screen and (max-width: 800px) {
    .time-entry-card-content {
        background: white;
        color: var(--saltbox);
        display: grid;
        justify-content: center;
        text-align: center;
        grid-template-columns: 100%;
        padding: 8px 0;
    }
    .time-entry-card-description {
        padding: 0 20px 10px 20px;
    }
}