.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  padding: 16px;
}

.scrolling-body{
  overflow-y: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
}

.scrolling-body-content {
  padding: 16px;
  height: inherit;
  overflow-y: scroll ;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrolling-body-content::-webkit-scrollbar {
  display: none;
}

.scrolling-body-footer {
  background: var(--wildsand);
  position: absolute;
  bottom: 0;
  padding: 16px;
  width: 100%;
}

.pagination-bottom-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/* header */
.header-divider {
  width: 100%;
  height: 1px;
  background: var(--saltbox);
}

/* colors */

.blue{
  background: var(--blue);
}

.lima{
  background: var(--lima);
}

.purple{
  background: var(--purple);
}

.orange{
  background: var(--orange);
}

.neptune{
  background: var(--neptune);
}

.corduroy{
  background: var(--corduroy);
}

.nandor {
  background: var(--nandor);
}

.watermelon {
  background: var(--watermelon);
}

.watermelondark {
  background: var(--watermelondark);
}

.color-bubble {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.color-indicator-container {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.color-indicator {
  height: 15px;
  width: 15px;
  border-radius: 20%;
}

/* primary card */

.primary-card-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.primary-card-container-user-card {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 30rem;
}

.primary-card-header {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 2px;
  font-weight: 500;
}

.primary-card-content-time-entry {
  background: white;
  color: var(--saltbox);
  display: grid;
  grid-template-columns:  10% 5% 10% 10% 40% 20%;
  gap: 20px;
  padding: 8px 8px;
  align-items: center;
}

.primary-card-content-project {
  background: white;
  color: var(--saltbox);
  display: grid;
  justify-content: center;
  grid-template-columns: 10% 70% 15%;
  padding: 8px 0;
}

.primary-card-content-team {
  background: white;
  color: var(--saltbox);
  justify-content: center;
  /* grid-template-columns: 10% 70% 15%; */
  padding: 8px 8px;
  text-align: center;
}

.primary-card-content-user {
  background: white;
  color: var(--saltbox);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* grid-template-columns: 10% 70% 15%; */
  padding: 8px 8px;
  text-align: center;
}

.primary-card-content-time-entry-type {
  background: white;
  color: var(--saltbox);
  display: grid;
  justify-content: center;
  grid-template-columns: 5% 15% 70% 10%;
  padding: 8px 0;
}

.primary-card-content-item-centered {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.primary-card-content-action-panel {
  display: flex;
  justify-content: center;
  gap: 2rem;
  color: var(--nandor);
}

.primary-card-description {
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: .3s ease;
  pointer-events: none;
  word-wrap: break-word;
}

.primary-card-description-project {
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: .3s ease;
  pointer-events: none;
  max-width: 900px;
  word-wrap: break-word;
}

.primary-card-description-project-expanded {
  text-overflow: clip;
  white-space: pre-wrap;
  overflow: no-content;
  transition: .3s ease;
  height: auto;
}

.projects-form-teams-button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 14rem;
}

.primary-card-description::first-line {
  color: var(--saltbox);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: .3s ease;
}

.primary-card-description-expanded {
  text-overflow: clip;
  white-space: pre-wrap;
  overflow: no-content;
  transition: .3s ease;
  height: auto;
}

.primary-card-info-btn {
  background: transparent;
  border: none;
  color: var(--neptune);
}

.primary-card-info-btn:hover {
  background: transparent;
  border: none;
  color: var(--bermuda);
  transition: .3s ease;
  transform: scale(1.2);
  cursor: pointer;
}

.primary-card-edit-btn {
  background: transparent;
  border: none;
  color: var(--nandor);
}

.primary-card-edit-btn:hover {
  background: transparent;
  border: none;
  color: var(--neptune);
  transition: .3s ease;
  transform: scale(1.2);
  cursor: pointer;
}

.primary-card-delete-btn {
  background: transparent;
  border: none;
  color: var(--nandor);
}

.primary-card-delete-btn:hover {
  background: transparent;
  border: none;
  color: var(--watermelon);
  transition: .3s ease;
  transform: scale(1.2);
  cursor: pointer;
}


@media only screen and (max-width: 840px) {
  .primary-card-content-time-entry {
    background: white;
    color: var(--saltbox);
    display: grid;
    justify-content: center;
    text-align: center;
    grid-template-columns: 100%;
    padding: 8px 0;
  }
  .primary-card-content-project {
    background: white;
    color: var(--saltbox);
    display: grid;
    justify-content: center;
    text-align: center;
    grid-template-columns: 100%;
    padding: 8px 0;
  }
  .primary-card-description {
    padding: 0 20px 10px 20px;
  }
  .primary-card-content-time-entry-type {
    background: white;
    color: var(--saltbox);
    display: grid;
    justify-content: center;
    text-align: center;
    grid-template-columns: 100%;
    padding: 8px 0;
  }
}

@media screen and (min-width:839px) and (max-width:1200px) {
  .primary-card-content-time-entry{
    grid-template-columns:  10% 5% 15% 16% 30% 10%;
    gap: 15px;
  }
}

/* form */

.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.form-centered {
  width: 70%;
}

.date-picker {
  width: 100%;
}

.inline-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
}

/* ant design components style overrides */

/* pagination */

.ant-pagination-item:hover  {
  border: none !important;
  background-color: var(--mercury) !important;
  border-radius: 10px !important;
  color: var(--nandor) !important;
}

.ant-pagination-item {
  border-radius: 10px !important;
  border: none !important;
  font-weight: 600;
  color: var(--nandor) !important;
  transition: .3s ease !important;
  background-color: var(--wildsand) !important;
}

.ant-pagination-item:active {
  border-radius: 10px !important;
}

a {
  color: var(--nandor) !important;
}

.ant-pagination-item-active a {
  border-radius: 10px !important;
  color: white !important;
  background-color: var(--nandor);
  border: none !important;
  outline: none !important;
  font-weight: 600;
  /*transition: all .3s ease !important;*/
  transition: all .3s ease, border-radius 0s !important ;
}

.ant-pagination-item-active {
  border-radius: 10px !important;
}


.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 10px !important;
  border: none !important;
  font-weight: 600;
  color: var(--nandor) !important;
  transition: .3s ease !important;
  background-color: var(--wildsand) !important;
}

.ant-pagination-prev:hover .ant-pagination-item-link:hover, .ant-pagination-next:hover .ant-pagination-item-link:hover {
  border-radius: 10px !important;
  background-color: var(--mercury) !important;
}

.ant-pagination-item-ellipsis{
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--corduroy) !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--corduroy) !important;
}

.ant-pagination-options-size-changer{
  display: none !important;
}

/* input */

.ant-input, .ant-input-affix-wrapper  {
  border-radius: 10px !important;
  border-color: var(--saltbox) !important;
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--neptune) !important;
  box-shadow: 0 0 0 2px rgb(24 200 144 / 20%) !important;
}

.ant-input:hover{
  border-color: var(--neptune) !important;
}

.ant-input-affix-wrapper:hover {
  border-color: var(--neptune) !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: var(--neptune) !important;
  box-shadow: 0 0 0 2px rgb(24 200 144 / 20%) !important;
}

.ant-input-prefix {
  color: var(--nandor);
}

.ant-input-suffix {
  color: var(--nandor);
}

input[type="date"]::before{
  content: attr(placeholder);
  position: absolute;
  color: rgba(0,0,0,0);
}

/* Hides default calendar icon on Chrome and shows the calendar picker on input click */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* Hides default time icon on Chrome and shows the calendar picker on input click */
input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* select */

.ant-select {
  min-width: 124px !important;
}

.ant-select-selector {
  border-color: var(--saltbox) !important;
  border-radius: 10px !important;
}

.ant-select-selector:hover {
  border-color: var(--patina) !important;
}

.ant-select-single{
  border-color: red !important;
}

.ant-select-focused {
  border-color: red !important;
  border-radius: 10px !important;
}

.ant-select-item-option-selected{
  background-color: var(--neptune) !important;
  color: white !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: 0 0 0 2px rgb(24 200 144 / 20%) !important;
  border-color: var(--patina) !important;
}

/* date picker */

.ant-picker-suffix {
  color: var(--nandor) !important;
}

.ant-picker {
  border-radius: 10px !important;
  border-color: var(--saltbox) !important;
}

.ant-picker:hover {
  border-color: var(--patina) !important;
}

.ant-picker-focused {
  border-color: var(--patina) !important;
  box-shadow: 0 0 0 2px rgb(24 200 144 / 20%) !important;
}

.ant-picker-active-bar {
  background: var(--corduroy) !important;
}

/* button */

.ant-btn{
  border-color: var(--nandor) !important;
  border-radius: 10px !important;
  color: var(--nandor) !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-btn:hover{
  border-color: var(--bermuda) !important;
  color: var(--silvertree) !important;
}
.ant-btn:focus{
  border-color: var(--silvertree) !important;
  color: var(--silvertree) !important;
}
.ant-btn:active{
  border-color: var(--nandor) !important;
  color: var(--nandor) !important;
}

.ant-btn-primary{
  background: var(--silvertree) !important;
  border-color: var(--silvertree) !important;
  border-radius: 10px !important;
  color: white !important;
}

.ant-btn-primary:hover{
  background: var(--bermuda) !important;
  border-color: var(--bermuda) !important;
  color: white !important;
}
.ant-btn-primary:focus{
  background: var(--bermuda) !important;
  border-color: var(--bermuda) !important;
  color: white !important;
}
.ant-btn-primary:active{
  background: var(--nandor) !important;
  border-color: var(--nandor) !important;
  color: white !important;
}

.ant-btn-danger{
  background: var(--watermelon) !important;
  border-color: var(--watermelon) !important;
  border-radius: 10px !important;
  color: white !important;
}

.ant-btn-danger:hover{
  background: var(--watermelonlite) !important;
  border-color: var(--watermelonlite) !important;
  color: white !important;
}
.ant-btn-danger:focus{
  background: var(--watermelon) !important;
  border-color: var(--watermelon) !important;
  color: white !important;
}
.ant-btn-danger:active{
  background: var(--watermelondark) !important;
  border-color: var(--watermelondark) !important;
  color: white !important;
}

/* spinner */

.ant-spin-dot-item {
  background-color: var(--silvertree) !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pdf-export-btn{
  color: white !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.reports-button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.users-options-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
}

.users-body{
  padding: 0px 15px 0px 15px;
}

.users-search-container{
  display: flex;
  flex-direction: column;
}

.users-search-form-container{
  display: flex;
  flex-direction: row;
}

.team-card-button-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15rem;
}

.calendar-container{
  padding-top: 20px;
  height: 50rem;
}

.fc-event {
  height:60px !important;
}

.fc-event-time, .fc-event-title {
  padding: 0 1px;
  white-space: normal;
  }

  @media screen and (max-width: 767px) {
    .fc-toolbar.fc-header-toolbar {
      flex-direction: column;
      order: 2;
    }
    .fc-toolbar-chunk {
      display: table-row;
      text-align: center;
      padding: 5px 0;
    }
    .fc-event {
      width:95%;
    }
  }

.home-logo{
  padding-top: 40px;
  filter: invert(60%) sepia(70%) saturate(270%) hue-rotate(106deg) brightness(140%) contrast(60%);
  width: 40%;
  padding-bottom: 20px;
}

.home-label{
  font-size: 1.5rem;
}

.home-description{
  font-size: 1rem;
}

@media screen and (max-width: 767px) {
.timesheet-body-container{
  padding-bottom: 50%;
}
}

@media screen and (min-width:321px) and (max-width:768px) {
  .timesheet-body-container{
    padding-bottom: 50%;
  }
}

@media screen and (min-width:321px) and (max-width:768px) {
  .view-select{
    display: none !important;
  }
}


