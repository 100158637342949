.delete-confirmation-body{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 13rem;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 5%;
    z-index: 99;
}

.delete-confirmation-content{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 100px;
    padding: 3rem 20px 0px 20px;
}

.delete-confirmation-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}