.form-header-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 25px;
    line-height: 20px;
    width: 100%;
    gap: 5%;
    padding: 20px 0 30px 0;
}

.return-button {
    border: none;
    background: transparent;
    font-size: 20px;
    color: var(--nandor);
}