.nav-container {
    background-color: var(--patina);
    width: 60px;
    z-index: 30;
    transition: .3s ease;
    padding: 20px 0 0 10px;
    display: flex;
    position: fixed;
    left: 0;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
}

.nav-container:hover {
    width: 190px;
    filter: drop-shadow(4px 4px 11px rgba(0, 0, 0, 0.25));
}

.nav-container-expanded-locked {
    width: 190px !important;
    filter: none !important;
}

.nav-container-locked {
    width: 60px !important;
    filter: none !important;
}

.nav-logo {
    width: 170px;
    height: 40px;
}

.nav-buttons-container{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.nav-lock-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.nav-lock-btn {
    background: transparent;
    cursor: pointer;
    border: none;
    color: white;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-left: 5px;
}