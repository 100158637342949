.time-inputs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.labeled-input{
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .time-inputs-container {
        flex-direction: row;
    }
}
