.total-card-container{
    width: 100%;
    margin: 15px 0;
    padding:6px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: .3s ease;
}

.total-card-container-scrolling{
    box-shadow: none;
    transition: .3s ease;
}

.total-card-total-hour{
    font-weight: bold;
    padding: 6px;
    font-size: 16px;
}

.total-card-type-container{
    padding: 6px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.total-card-type{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}

.total-card-type-color{
    width: 12px;
    height: 12px;
    border-radius: 3px;
    margin: 0 6px;
}