body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--saltbox);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--wildsand) !important;
}

*{
  margin-top: 0;
}

.main-container {
  margin-left: 60px;
  transition: .3s ease;
}

.main-container-nav-expanded {
  margin-left: 190px;
  transition: .3s ease;
}

:root {
  --corduroy : #5a716b;
  --patina : #66A48D;
  --nandor: #445552;
  --silvertree: #5dc19d;
  --neptune: #86C5AE;
  --mercury: #E5E5E5;
  --wildsand: #F6F6F6;
  --saltbox: #62566B;
  --concrete: #acacac;
  --watermelondark: #ea1647;
  --watermelon: #FE4E78;
  --watermelonlite: #fe7f98;
  --bermuda: #6ed8b2;
  --orange: #eb8052;
  --purple: #8073d1;
  --lima: #8db619;
  --blue: #4c90cf;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection {
  background: var(--neptune) !important;
}