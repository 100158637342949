.icon-btn{
    border: none;
    border-radius: 50%;
    background-color: var(--nandor);
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transition: .3s ease;
    flex-shrink: 0;
}

.icon-btn:hover{
    background-color: var(--patina);
}

.icon-btn:active{
    transition: .05s ease;
    background-color: var(--neptune);
    box-shadow: inset 0 2px 2px 1px rgba(0,0,0,0.25);
}

.icon-btn-icon{
    width: 16px;
    height: 16px;
}