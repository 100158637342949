.nav-btn {
    background-color: var(--patina);
    width: 40px;
    height: 40px;
    border: 1px solid var(--patina);
    border-radius: 10px;
    font-size: 19px;
    color: white;
    margin: 8px 0;
    transition: .3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-left: 10px;
}

.nav-btn-active {
    background-color: var(--corduroy);
    border: 1px solid var(--neptune);
    box-shadow: inset 0 2px 2px 1px rgba(0,0,0,0.25);
}

.nav-btn:hover {
    background-color: var(--silvertree);
    cursor: pointer;
}

.nav-btn-active:hover {
    background-color: var(--corduroy);
    border: 1px solid var(--neptune);
}

.nav-btn-expanded{
    width: 170px;
}

.nav-btn-label {
    font-size: 16px;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}